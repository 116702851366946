import dynamic from 'next/dynamic';
import Layout from '@/components/layout';
import useLocale from '@/hooks/useLocale';

const Content = dynamic(() => import('@/components/content'));

const Page: React.FC = () => {
    const locale = useLocale();

    return (
        <Layout title="JF Woodpall" description={locale.description}>
            <Content />
        </Layout>
    );
};

export default Page;
